:root {
    --bkg: #FAFAFA;
    --font: #808080;
    --red: #E30613;
    --f-red: rgba(227, 6, 19, 0.39);
    --yellow: #FECD00;
    --l-yellow: rgba(254, 205, 0, 0.7);
    --black: #2F2F2F;
    --white: #FBFCFC;
    --green: #42CFA5;
    --blue: #2A5CDC;
    --google: #E02D37;
    --facebook: #2A5CDC;
    --l-gray: rgba(250, 250, 250, 0.46);
    --m-gray: #CBCBCB;
    --f-gray: #F1F1F1;
    --gray: #C0C0C0;
    --whiter: #F8F8F8;
    --btn-shadow: 0px 8px 29px rgba(227, 6, 19, 0.39);
    --btn-background: #C4C4C4;
    --padding: 30px 100px;
}

@font-face {
  font-family: 'MontserratLight';
  src: URL("/font/Montserrat-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'MontserratRegular';
  src: URL("/font/Montserrat-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'MontserratMedium';
  src: URL("/font/Montserrat-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'MontserratBold';
  src: URL("/font/Montserrat-Bold.ttf") format("truetype");
  font-display: swap;
}

.main-container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
}

@media screen and (max-width: 768px) {
    .main-container {
        margin-top: 100px;
        padding: 0;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}


@keyframes visible {
    0% {
        opacity: 0;
        visibility: hidden;
        position: relative;
        top: 10px;
    }

    100% {
        opacity: 1;
        visibility: visible;
        position: relative;
        top: 0;
    }
}

button {
    outline: 0 !important;
    cursor: pointer;
}


a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
}

.calendarStyle .rmdp-selected span{
    background-color: var(--red) !important;
}

.calendarStyle .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: var(--f-red) !important;
}

.calendarStyle ul.rmdp-panel-body li{
    background-color: var(--red) !important;
}

.calendarStyle .rmdp-week-day{
    color: var(--red) !important;
}

.calendarStyle .rmdp-arrow-container i.rmdp-arrow{
    border-color: var(--red) !important;
}

.calendarStyle .rmdp-arrow-container.disabled i.rmdp-arrow{
    border-color: var(--black) !important;
}

.calendarStyle .rmdp-arrow-container:hover{
    background-color: var(--f-red) !important;
}

.calendarStyle .rmdp-panel-header {
    width: 0px;
    visibility: hidden;
}
.calendarStyle .rmdp-panel-header:after {
    content: 'Fechas' !important;
    visibility: visible;
}